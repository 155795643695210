
.c-box {
  background: $white;
  border: 2px solid $black;
  padding: 30px;
  margin-bottom: 60px;

  @include mq-max(md){
    padding: 15px;
  }

  &__heading {
    display: flex;
    margin-bottom: 20px;

    @include mq-max(md){
      display: block;
    }
    & > * {
      white-space: nowrap;

      & + * {
        white-space: inherit;
        margin-left: 20px;
        margin-top: 0!important;
        margin-bottom: 0;
        line-height: 1.4;

        @include mq-max(md){
          margin-left: 0;
        }
      }
    }
  }
}
