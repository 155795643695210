// ヘッダーメニュートグルボタン
.c-toggleBtn {
  display: none;

  @include mq-max(md) {
    position: absolute;
    right: 0;
    top: 0;
    height: 63px;
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &::before,
  &::after {
    content: '';
    width: 29px;
    height: 2px;
    background: $black;
    display: block;
    position: relative;
    transition: transform $transition;
  }
  &::after {
    margin-top: 19px;
    box-shadow: 0 -10px 0 $black;
  }
  &.is-active {
    &::before {
      transform: rotate(45deg) translate(4px, 4px);
    }
    &::after {
      box-shadow: none;
      transform: rotate(-45deg) translate(11px, -11px);
    }
  }
}
