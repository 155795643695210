.u- {
  &txt {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
    &--bold {
      font-weight: bold;
    }
    &--Lg {
      font-size: 24px;
      letter-spacing: .05em;

      @include mq-max(md){
        font-size: 15px;
      }
    }
  }
}
