.c-topicsPath {
  margin: 17px 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  overflow: hidden;

  @include mq-max(md){
    margin: 10px 15px;
  }
  @include mq-max(sm) {
    display: none;
  }

  a {
    text-decoration: none;
    color: $black;
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0 15px 0 15px;
    white-space: nowrap;
  }

  li{
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    border-right: none;
    font-size: 16px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      border-top: 2px solid #000;
      border-right: 3px solid #000;
      height: 14px;
      width: 20px;
      top: 9px;
      right: -9px;
      transform: rotate(70deg) skew(42deg);
      background: $white;
      z-index: 1;
    }

    &:hover {
      a {
        color: $white;
        background: $black;
      }

      &::after {
        background: $black;
      }
    }

    &:first-child {
      padding-left: 0;
      border-left: 2px solid $black;
       a {
         padding-left: 10px;
       }
    }
    &:last-child {
      padding: 0 15px 0 15px;
      display: flex;
      height: 34px;
      align-items: center;
      border-right: 2px solid $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include mq-max(md){
      font-size: 10px;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}
