// ヘッダー
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 63px;
  z-index: 200;
  background: $whiteGradient;
  transition: .3s;

  &.is-active {
    background: $white;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    height: 63px;
    align-items: center;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    z-index: 100;

    @include mq-max(md) {
      width: 100%;
      display: block;
      padding: 0 15px;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }
}

// ヘッダーのロゴ
.c-logo {
  font-size: 15px;
  display: flex;
  align-items: center;
  height: 63px;
  transition: opacity .3s!important;

  @include mq-max(md) {
    display: inline-flex;
  }

  img {
    height: auto;
    @include mq-max(lg){
      width: 250px;
    }
    @include mq-max(md) {
      width: 280px;
    }
  }

  &:hover {
    color: $textColor;
    opacity: 0.5;
  }

  // トップのホバーイベント禁止
  &:not(a) {
    pointer-events: none;
  }

  &__subTitie {
    padding-left: 7px;
    margin-left: 7px;
    display: block;
    border-left: 2px solid $black;

    img {
      display: inline-block;
      margin-right: 5px;
    }

    span {
      display: none;
    }
    @include mq-max(md) {
      img {
        display: block;
      }
    }
  }
}
