// 下層全体に適用するCSS
//import from module
//ぱんくず
@import "../util/all";
@import "../module/topicsPath";

//下層タイトル
.l-lowerCaption {
  border: 1px solid $gray-l;
  margin: 0 20px;
  background: $grayGradient;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;

  @include mq-max(md) {
    margin: 0;
    padding: 15px;
    height: 150px;
    text-align: center;
    font-size: 20px;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  下層

///////////////////////////////////////////////////////////////////////////////////////////////////////


.l-content{
  padding: 0 20px;
  margin: 50px auto 50px;
  max-width: $contentWidth + 40px;
  overflow: hidden;

  @include mq-max(lg) {
    max-width: $contentWidth;
  }

  @include mq-max(md) {
    margin: 40px auto 30px;
    padding: 0 15px;
  }
  &--sm {
    @extend .l-content;
    max-width: $contentWidthSm;
  }

  &--md {
    @extend .l-content;
    max-width: $contentWidthMd;
  }

  &--fluid {
    margin: 50px 15px;
    @include mq-max(sm) {
      margin-left: 0;
      margin: 40px 0;
    }
  }
}
