//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;
    body {
      overflow: hidden;
    }
  }
}

body {
  color: $textColor;
  font-family: $sanserif;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";
  @if $is-fixed == true {
    margin-top: $headerHeight;
    @include mq-max(md) {
      margin-top: $headerHeight-sp;
    }
  }
}


//  見出し
///////////////////////////////////////////////////////////////////////////////////////////////////////
h2,h3,h4,h5 {
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 0.04em;
}
h1 {
  font-size: 30px;
  letter-spacing: 0.04em;
  @include mq-max(xs) {
    font-size: 24px;
  }
}
h2 {
  font-size: 28px;
  margin-bottom: 30px;
  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}
h3 {
  font-size: 22px;
  margin-bottom: 15px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
h4 {
  font-size: 18px;
  margin-bottom: 15px;
  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
h5 {
  font-size: 16px;
  margin-bottom: 10px;
  @include mq-max(xs) {
    font-size: 16px;
    margin-bottom: 10px;
  }
}

//  文章
///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  &[target="_blank"]{
    &:after{
      @include fa('f35d');
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &[href$=".pdf"] {
    &:after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing:antialiased;
    }
  }
  &:not([class^="c-btn"]) {
    color: $keyColor;
    transition: color $transition;

    &:hover {
      color: $blue;
    }
  }
}

p {
  font-size: 16px;
  margin-bottom: 1.3em;
  line-height: 2;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(xs) {
    font-size: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}


//  強調
strong {
  font-weight: 600;
}

//  画像
figure {
  img {
    width: 100%;
  }
}

img {
  max-width: 100%;
  height: auto;
}

// Lists
ul, ol {
  list-style-type: none;
}

//  電話番号CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-tel {}

