.c-cvArea {
  margin: 140px 20px 20px;
  text-align: center;
  background: $grayGradient;
  padding: 60px 20px;

  @include mq-max(md) {
    margin: 80px 15px 15px;
    padding: 45px 15px;
  }

  &__heading {
    font-size: 36px;

    @include mq-max(md) {
      font-size: 24px;
    }
  }

  p {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 55px;

    @include mq-max(md) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
}
