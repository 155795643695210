///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  &--more {
    width: $btn-width;
    color: $black;
    align-items: center;
    height: 66px;
    display: flex;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid $black;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    transition: $transition;
    line-height: 1;
    background: $white;
    letter-spacing: 0.1em;
    transition: $transition;

    @include mq-max(sm) {
      height: 60px;

    }
    @media screen and (max-width: 420px) {
      width: 100%;
    }

    &::after {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      margin-top: 2px;
      background: $arrow;
      transition: $transition;
    }

    &::before {
      z-index: -1;
      content: '';
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background: #000;
      position: absolute;
      transition: $transition;
    }

    &:hover {
      cursor: pointer;
      color: $white;
      transition: transform $transition;

      &:after {
        background: $arrowHover;
        transform: translateX(8px);
      }

      &::before {
        width: 100%;
      }
    }
  }

  &--more--sm {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    padding-bottom: 10px!important;

    &::after {
      vertical-align: -2px;
      margin-left: 5px!important;
      content: ''!important;
      width: 25px;
      height: 14px;
      display: inline-block;
      background: $arrow;
      transition: $transition;
      transform: translateX(-5px);
    }

    &:hover {
      &::after {
        transform: translateX(0);
      }
    }
  }

  &--toTop {
    width: 48px;
    height: 48px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: rgba($white, .49);
    border: 2px solid $black;
    z-index: 10;

    &:hover {
      cursor: pointer;
      &::before {
        top: 0;
        @include mq-max(md) {
          top: 10px;
        }
      }
    }

    &::before {
      @include mq-max(md) {
        transition: none;
      }
      transition: $transition;
      position: absolute;
      width: 28px;
      height: 80px;
      top: 10px;
      left: 8px;
      content: '';
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC45MDEiIGhlaWdodD0iODEuNDIiIHZpZXdCb3g9IjAgMCAzMC45MDEgODEuNDIiPgogIDxnIGlkPSLjgrDjg6vjg7zjg5dfNTk5NTkiIGRhdGEtbmFtZT0i44Kw44Or44O844OXIDU5OTU5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTMyMi41NDkgLTcxNi4xNDEpIj4KICAgIDxnIGlkPSLjgrDjg6vjg7zjg5dfNTk5NjAiIGRhdGEtbmFtZT0i44Kw44Or44O844OXIDU5OTYwIj4KICAgICAgPGxpbmUgaWQ9Iue3ml85OSIgZGF0YS1uYW1lPSLnt5ogOTkiIHgyPSIwLjUiIHkyPSI4MCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMzOCA3MTcuNTU1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiLz4KICAgICAgPGxpbmUgaWQ9Iue3ml8xMDAiIGRhdGEtbmFtZT0i57eaIDEwMCIgeDE9IjE0LjAzNyIgeTI9IjEzLjcyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTMyMy45NjMgNzE3LjU1NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgICAgIDxsaW5lIGlkPSLnt5pfMTAxIiBkYXRhLW5hbWU9Iue3miAxMDEiIHgyPSIxNC4wMzciIHkyPSIxMy43MjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMzggNzE3LjU1NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K) center top / 28px auto no-repeat;
    }
  }

  &--toBottom {
    color: $black;
    text-decoration: none;
    writing-mode: vertical-rl;
    font-size: 13px;
    font-weight: bold;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    letter-spacing: 0.1em;

    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 126px;
      background: $black;
      position: absolute;
      top: 6em;
      left: 0.9em;

      @include mq-max(md) {
         height: 30px;
       }
    }
  }
}
