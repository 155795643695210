///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////
// ページのタイトル

.c-heading{
  &--center {
    text-align: center;
    font-size: 28px;
    margin-bottom: 45px;

    @include mq-max(md){
      font-size: 24px;
      margin-bottom: 30px;
    }
    &--sm {
      @extend .c-heading--center;
      font-size: 22px;
      margin-bottom: 60px;

      @include mq-max(md){
        font-size: 18px;
        margin-bottom: 30px;
      }
    }
  }

  &--hasLogo {
    text-align: center;
    margin-bottom: 50px;
    padding-top: 20px;

    @include mq-max(sm) {
      margin-bottom: 30px;
      padding-top: 10px;
    }
    &__main {
      font-size: 36px;
      background: url(/inc/image/parts/logo_etak.png) center top / 48px auto no-repeat;
      padding-top: 40px;
      letter-spacing: .1em;
      margin-bottom: 0;
      margin-bottom: 5px;

      @include mq-max(sm) {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
    &__child {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
      letter-spacing: .1em;

      @include mq-max(sm) {
        font-size: 16px;
      }
    }
  }

  &__sub {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;

    &::after {
      margin-left: 5px;
      content: '';
      display: inline-block;
      width: 120px;
      height: 2px;
      background: $black;
    }
  }
  &__main {
    display: block;
    font-size: 28px;
    letter-spacing: .05em;

    @include mq-max(sm) {
      font-size: 20px;
    }
  }

  &--lg {
    padding-top: 40px;
    letter-spacing: .04em;

    &--underline {
      font-size: 32px;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 2px solid $black;
      letter-spacing: .05em;

      @include mq-max(sm) {
        font-size: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }

    &--center {
      max-width: 860px;
      text-align: center;
      margin: 0 auto 100px;
      padding-top: 100px;
      letter-spacing: .05em;

      @include mq-max(sm) {
        padding-top: 70px;
        margin-bottom: 50px;
      }

      .c-heading__sub {
        justify-content: center;
         &::after {
           width: 100px;
         }
         &::before {
          margin-right: 5px;
          content: '';
          display: inline-block;
          width: 100px;
          height: 2px;
          background: $black;
        }
      }
    }
  }

  &--md {
    &--center {
      margin-top: 120px;
      margin-bottom: 50px;
      text-align: center;

      .c-heading__main {
        font-size: 28px;

        @include mq-max(sm) {
          font-size: 20px;
        }
      }

      .c-heading__sub {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        display: block;

        &::after {
          content: none;
        }
      }
    }
  }
}
