.l-footer {
  font-size: 15px;

  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;

    @include mq-max(md) {
      flex-direction: column-reverse;
    }


    @include mq-max(content) {
      padding: 50px 20px 0;
    }
    @include mq-max(xs) {
      margin-bottom: -30px;
    }
  }
  &__info {
    display: block;

    @include mq-max(md) {
      img {
        width: 165px;
      }
    }

    address {
      font-style: normal;
      margin-bottom: 10px;
       @include mq-max(md) {
         font-size: 13px;
       }
    }
    a[target=_blank] {
      font-size: 13px;
      @include mq-max(md) {
        font-size: 12px;
      }
    }
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;

    @include mq-max(md) {
      margin-bottom: 60px;
    }

    a:not(.is-normal) {
      text-decoration: none;
      color: $black;
      font-weight: bold;
      position: relative;
      padding-bottom: 3px;
      display: inline-block;

      &::after {
        position: absolute;
        content: '';
        width: 0;
        display: block;
        bottom: 0;
        left: 0;
        height: 2px;
        background: $black;
        transition: $transition;
      }

      &:hover {

        &::after {
          width: 100%;
        }
      }
    }

    li {
      margin-bottom: 20px;
    }

    ul {
      width: 200px;

      @include mq-max(md) {
        width: 50%;

        &:first-child {
          width: 100%;
        }
      }
    }
  }

  //Copyright
  &__copyright {
    padding: 10px 0;
    margin: 0 auto;
    padding: 20px 0;
    max-width: $contentWidth;

    @include mq-max(md) {
      padding: 50px 15px 20px;
    }
  }
}
