//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$contentWidth    : 1170px;// 全体のコンテンツ幅
$contentPadding  : 30px;//パディング:gridlexでも使用
$tablet-break    : 768px;//タブレットでのブレイクポイント
$mobile-break    : 568px;//モバイルでのブレイクポイント
$padding-break   : 40px;//基本のコンテンツ余白
$contentWidthSm  : 860px;//細いコンテンツ馬場
$contentWidthMd  : 980px;//細いコンテンツ馬場
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定

//----------------------------------------------------------------
//    header fixed設定
//----------------------------------------------------------------
$is-fixed        : true;
$headerHeight    : 61px;
$headerHeight-sp : 61px; //ここを変えたら、menu.jsの「headerHeight」の値も変えること！


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width(gridlexに合わせる)
$breakpoint-max: (
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'md': 'screen and (max-width: 1024px)',
  'content': 'screen and (max-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (max-width: 1280px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'md': 'screen and (min-width: 1024px)',
  'content': 'screen and (min-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (min-width: 1280px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white         : #FFFFFF;
$gray          : #CCCCCC;
$black         : #000000;
$yellow        : #ECBB46;
$gray-l        : lighten( $gray, 15%);
$red           : #c82e2e;
$deep-blue     : #003DC7;
$blue          : #0081d1;
$blue-l        :#D3ECF4;
$whiteGradient :linear-gradient(to bottom, rgba(#fff, 0.9) 60%, rgba(#fff, 0) 100%);
$grayGradient  :radial-gradient(#f9f9f9 40%,#e8e8e8 120%);
$blueGradient  :radial-gradient(#f9f9f9 40%,#e8e8e8 120%);
$paleBlueGradient :linear-gradient(45deg, rgba(#7CA3DB, 0.1) -20%, rgba(#70C7CB, .1) 120%);
$greenGradient :linear-gradient(45deg, rgba(#7CA3DB, 1) -20%, rgba(#4CACB1, 1) 120%);
$paleGray      :#EAEAEA;
$keyColor      : $deep-blue; //キーカラー
$accentColor   : $red; //アクセントカラー
$hoverColor    : $blue; //アクセントカラー

$bdrColor        : $gray;

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$FontAwesome: 'Font Awesome 5 Free';

$textColor: $black;
$textColorLight: lighten($textColor,10);
$textColorDark: darken($textColor,10);
$lineHeight: 1.5; // 基本的な行間

// リストのキラキラ
$kirakira: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cg%20%20fill%3D%22%23fff%22%20stroke%3D%22%23707070%22%20stroke-width%3D%221%22%20opacity%3D%220%22%3E%0A%20%20%20%20%3Crect%20width%3D%2216%22%20height%3D%2216%22%20stroke%3D%22none%22%2F%3E%0A%20%20%20%20%3Crect%20x%3D%220.5%22%20y%3D%220.5%22%20width%3D%2215%22%20height%3D%2215%22%20fill%3D%22none%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%20%20%3Cpath%20d%3D%22M8%2C0l2%2C6%2C6%2C2-6%2C2L8%2C16%2C6%2C10%2C0%2C8%2C6%2C6Z%22%20fill%3D%22%23003dc7%22%2F%3E%0A%3C%2Fsvg%3E%0A';

// サンセリフ・ゴシック体
$sanserif: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
$numFont: 'Oswald', sans-serif;

//----------------------------
//    コンテンツ
//---------------------------
$content-bg       : $white;
$font-size        : 190%;//全体のフォントサイズ
$full-size   : 100%;//基本のコンテンツ余白

//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 3px;//全体の角丸サイズ
$transition: .3s ease;

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------
$btn-width        : 360px;//PCサイトアクションボタンの大きさ
$border-btn       : none;//テーブルのボーダーカラー
$border-btn-width : 0px;//テーブルのボーダーサイズ
$border-txt-color : $white;//テーブルのボーダーサイズ
$arrow            : url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3C!--%20Generator%3A%20Adobe%20Illustrator%2024.3.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200)%20%20--%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%0A%09%20y%3D%220px%22%20width%3D%2219.192px%22%20height%3D%2219.192px%22%20viewBox%3D%220%200%2019.192%2019.192%22%20style%3D%22enable-background%3Anew%200%200%2019.192%2019.192%3B%22%0A%09%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M9.746%2C0.146l-1.7%2C1.691l6.7%2C6.709h-14.6v2.4h14.6l-6.7%2C6.7l1.7%2C1.692l9.6-9.6L9.746%2C0.146z%22%2F%3E%0A%3C%2Fsvg%3E%0A') center no-repeat;
$arrowHover       : url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%0A%09%20y%3D%220px%22%20width%3D%2219.192px%22%20height%3D%2219.192px%22%20viewBox%3D%220%200%2019.192%2019.192%22%20style%3D%22enable-background%3Anew%200%200%2019.192%2019.192%3B%22%0A%09%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M9.746%2C0.146l-1.7%2C1.691l6.7%2C6.709h-14.6v2.4h14.6l-6.7%2C6.7l1.7%2C1.692l9.6-9.6L9.746%2C0.146z%22%2F%3E%0A%3C%2Fsvg%3E%0A') center no-repeat;
