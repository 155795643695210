//global nav
.l-navigation {
  font-weight: bold;
  height: 63px;

  @include mq-max(md) {
    margin-left: -15px;
    margin-right: -15px;

    .l-navigation__bg {
      background: rgba($white, .5);
      position: absolute;
      left: 0;
      top: $headerHeight-sp;
      width: 100%;
      z-index: -1;
      display: none;
    }


    &.is-active {
      .l-navigation__inner {
        transform: translateX(0);
      }
    }

  }

  a {
    text-decoration: none;
    color: $black;

    &:hover {
      color: $black;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include mq-max(md) {
      position: absolute;
      right: 0;
      top: $headerHeight-sp;
      width: 250px;
      transform: translateX(100%);
      transition: $transition;
      display: block;
      background: #fff;
      padding: 0 20px 20px;
    }
  }

  &__item {
    @include mq-min(md) {
      height: 63px;
    }
    display: flex;
    align-items: center;
    display: flex;
    font-size: 15px;
    position: relative;
    margin-right: 20px;
    letter-spacing: 0.04em;

    @include mq-max(md) {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;
    }

    &--contact {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding-right: 20px;
      height: 63px;
      @include mq-max(md) {
        content: none;
        padding-right: 0;
      }
    }

    button,
    a {
      display: flex;
      align-items: center;
      color: $black;
      height: 34px;
      position: relative;
      letter-spacing: 0.04em;

      @include mq-max(md) {
        height: 44px;
      }

      i {
        margin-left: 3px;
        font-size: 10px;
      }

      &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        display: block;
        bottom: 0;
        left: 0;
        transition: $transition;
        background: $black;
      }

      &:hover {
        color: $black!important;

        &::after {
          width: 100%;
           @include mq-max(md) {
            content: none;
          }
        }
      }
    }
    @include mq-max(md) {
      button {
        display: none;
      }
    }

    &.is-current {
      position: relative;

      span {
        display: flex;
        align-items: center;
        height: 40px;
        @include mq-max(md) {
          height: 44px;
        }
      }

      &::before {
        position: absolute;
        background: $deep-blue;
        content: '';
        left: calc(50% - 1px);
        top: 0;
        width: 2px;
        height: 17px;
        display: block;

        @include mq-max(md) {
          left: -20px;
          height: 100%;
        }

      }
    }
  }
}
.l-dropDown {
  max-height: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  overflow: hidden;
  opacity: 0;
  transition: $transition;

  @include mq-max(md) {
    position: inherit;
    max-height: inherit;
    transform: none;
    opacity: 1;
  }

  li {
    white-space: nowrap;
    border-bottom: 1px solid #fff;

    @include mq-max(md) {
      margin-bottom: 10px;
    }

    &:last-child {
      border-bottom: 0;

      @include mq-max(md) {
        margin-bottom: 0;
      }
    }

    a {
      color: #fff;
      display: block;
      background: $keyColor;
      height: 33px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      transition: $transition;

      @include mq-max(md) {
        padding: 0;
        height: 44px;
        background: none;
        color: $black;
      }


      &:hover {
        color: $white!important;
        background: $hoverColor;

        @include mq-max(md) {
          background: none;
          color: $black!important;
        }
      }

      &::after {
        content: none;


      }
    }
  }
}

.is-opened {
  .l-dropDown {
    opacity: 1;
    max-height: 200px;
  }
}

a.c-btn--header--contact {
  border: 2px solid $black;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 43px;
  padding: 0 25px;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  transition: $transition;
  background: $white;

  @include mq-max(md) {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    background: $black;
    transition: $transition;
    z-index: -1;

    @include mq-max(md) {
      content: none;
    }
  }

  &:hover {
    color: #fff;

    @include mq-max(md) {
      color: $black;
    }

    &::after {
      width: 100%;
    }
  }
}
