.c-label {
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  line-height: 1;
  height: 40px;
  color: $white;
  background-color: $keyColor;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid $keyColor;
  letter-spacing: 0.1em;


  @include mq-max(md){
    font-size: 12px;
    height: 30px;
  }

  & + * {
    margin-top: 15px;
    @include mq-max(md){
      margin-top: 10px;
    }
  }

  &--sm {
    @extend .c-label;
    font-size: 14px;
    height: 30px;

    @include mq-max(md){
      font-size: 12px;
    }
  }

  &--xs {
    @extend .c-label--sm;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
  }

  &--point {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    border: 2px solid $black;
    line-height: 70px;
    font-family: "Oswald", serif;
    font-size: 50px;

    @include mq-max(sm) {
      width: 65px;
      font-size: 30px;
      line-height: 50px;
    }

    &::before {
      font-weight: bold;
      font-family: $sanserif;
      line-height: 1.5;
      padding: 5px;
      text-align: center;
      width: 100%;
      content: 'ポイント';
      font-size: 14px;
      border-bottom: 2px solid $black;
      @include mq-max(sm) {
        font-size: 10px;
        padding: 3px;
      }
    }
  }
}
