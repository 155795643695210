%c-list {
  list-style-type: none;
  position: relative;
  margin-bottom: 30px;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-list--disc {
  @extend %c-list;
  // 丸付き
  & > li {
    position: relative;
    padding-left: 1.2em;

    &:before {
      content: '';
      display: inline-block;
      background: $black;
      height: 1em;
      width: 1em;
      position: absolute;
      top: .6em;
      left: 0;
      transform: scale(.3);
      border-radius: 50%;
    }
  }
}

.c-list--num {
  @extend %c-list;
  counter-reset: num;

  & > li {
    counter-increment: num;
    position: relative;
    padding-left: 2em;

    &:before {
      width: 1.7em;
      position: absolute;
      left: 0;
      text-align: right;
      content: counter(num)".";
      font-family: $numFont;
    }
  }
}
