//テーブル
.c-table {
  border-collapse: collapse;
  width: 100%;

  td,th {
    padding: 20px;
    border: 2px solid $black;
    text-align: left;
    vertical-align: top;
  }
}
